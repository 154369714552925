import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
const ReferencesStoryCard = ({ image, title, children, to }) => (
  <Link to={to}>
    <div className="ReferencesStoryCard border-box container-fluid">
      <div className="cnt">
        <div className="image">{image}</div>
        <div className="content mt-8">
          <h3>{title}</h3>
          {children && <div className="mt-4">{children}</div>}
        </div>
      </div>
    </div>
  </Link>
)

ReferencesStoryCard.defaultProps = {}

ReferencesStoryCard.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default ReferencesStoryCard
