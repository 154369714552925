import React from 'react'
import { graphql } from 'gatsby'
import map from 'lodash/map'
import DatoCMSModel from '../../models/DatoCMSModel'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import PageHeader from '@c/page-header'

import Hero from '@v/references/hero'
import Story from '@v/references/story'
import Customers from '@v/references/customers'

import './index.css'

const Page = ({ data, pageContext }) => {
  const { page } = data
  const { title } = page
  const model = new DatoCMSModel(page)

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps() }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb parentPages={[]} currentPage={title} />
          </div>
        </div>
      </div>

      {/* header*/}
      <div className="container ta-center">
        <PageHeader title={page.heroTitle} />
      </div>

      <Hero
        locale={pageContext.locale}
        miniSuccessDetails={page.miniSuccessDetails}
      />

      <Story
        title={page.customerStoriesTitle}
        stories={page.customerStories}
        pageSettings={data.pageSettings}
      />

      <Customers
        title={page.customersTitle}
        customers={map(data.customers.edges, 'node')}
      />
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ReferencesPageQuery($locale: String) {
    page: datoCmsCustomerReferencesPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      heroTitle
      miniSuccessDetails {
        id
        titleBig
        titleSmall
        link
        logo {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
      customerStoriesTitle
      customerStories {
        id
        title
        description
        image {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
        caseStudy {
          slug
        }
      }
      customersTitle
    }

    customers: allDatoCmsCustomerReference(
      filter: { locale: { eq: $locale } }
    ) {
      edges {
        node {
          id
          name
          website
          logo {
            alt
            asset {
              localImage {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
          category {
            id
            title
            orderingNumber
          }
        }
      }
    }
    # Page Details
    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      locale
      ...PageSettingFragment
    }
  }
`
