import React from 'react'
import chunk from 'lodash/chunk'

import flow from 'lodash/fp/flow'
import groupBy from 'lodash/fp/groupBy'
import map from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'
import toPairs from 'lodash/fp/toPairs'

import PageHeader from '@c/page-header'
import ProjectImage from '@/components/project-image'

function CustomerLogos({ customers, columnClasses }) {
  return customers
    .filter(c => !!c) // cols may be null
    .map((customer, i) => (
      <div key={customer?.id || i} className={columnClasses}>
        <a
          href={customer.website}
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          <div className="border-box border-box--hover">
            {customer?.logo && <ProjectImage image={customer.logo} />}
          </div>
        </a>
      </div>
    ))
}

const Customers = ({ customers, title }) => {
  const groupedCustomers = flow(
    map(a => {
      (a?.category &&
        !a?.category?.orderingNumber &&
        (a.category.orderingNumber = 999)) ||
        true
      return a
    }),
    groupBy('category.id'),
    toPairs,
    orderBy(([, values]) => values[0].category.orderingNumber, 'asc'),
    map(([, values]) => ({
      category: values[0].category,
      rows: chunk(values, 4)
    }))
  )(customers)
  const columns = groupedCustomers

  return (
    <div className="container mt-60 mt-lg-120">
      {/* title */}
      <div className="row">
        <div className="col-12 ta-center">
          <PageHeader title={title} type="h3" />
        </div>
      </div>

      {columns.map((item, key) => {
        return (
          <div className="col-12" key={key}>
            <h4 className="mt-24">{item.category.title}</h4>
            {item.rows.map((cols, i) => {
              return (
                <div key={i} className="row mt-24">
                  <CustomerLogos
                    customers={cols}
                    columnClasses="col-6 col-lg-3 mb-32 mb-lg-0"
                  />
                </div>
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Customers
