import React from 'react'
import PropTypes from 'prop-types'

const ReferencesCard = ({ value, text, image, link }) => (
  <a
    href={link}
    className="ReferencesCard border-box"
    style={{ width: '100%' }}
  >
    <p className="h3">{value}</p>
    <p
      style={{ fontSize: '1em', lineHeight: '1', height: '2em' }}
      className="h5 mt-4"
    >
      {text}
    </p>
    <figure className="mt-24">{image}</figure>
  </a>
)

ReferencesCard.defaultProps = {}

ReferencesCard.propTypes = {
  value: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  image: PropTypes.node
}

export default ReferencesCard
