import React, { useRef, useEffect } from 'react'

import PageHeader from '@c/page-header'
import ReferencesStoryCard from '@c/references-story-card'
import Slider from '@c/slider-flickity'
import cn from 'classnames'
import makeSlug from '../../utils/make-slug'
import ProjectImage from '@/components/project-image'

function setBlurOption() {
  let showTransparentEffect = true
  if (typeof window !== 'undefined' && window.innerWidth <= 992) {
    showTransparentEffect = false
  }
  return showTransparentEffect
}

const Story = ({
  title,
  stories,
  transparentOption = setBlurOption(),
  pageSettings
}) => {
  const sliderStoryRef = useRef()
  const sliderArrowRef = useRef()
  useEffect(() => {
    if (!sliderArrowRef.current) return
    sliderArrowRef.current.on('select', () => {
      sliderStoryRef.current.select(sliderArrowRef.current.selectedIndex)
    })
  }, [sliderArrowRef])
  const makeLink = function(item) {
    const slug = item?.caseStudy?.slug || item?.slug
    return slug
      ? makeSlug.generic(
        pageSettings?.locale,
        pageSettings?.caseStudiesSlug,
        slug
      )
      : ''
  }
  return (
    <div className="container-fluid mt-60 mt-lg-80">
      {/* title */}
      <div className="row">
        <div className="col-12 ta-center">
          <PageHeader title={title} type="h3" />
        </div>
      </div>

      {/* stories */}
      <Slider
        className={cn('mt-32 mt-lg-60', {
          'short-transparent': transparentOption
        })}
        ref={sliderStoryRef}
      >
        {stories.map((item, index) => (
          <div key={index} className="ReferencesStoryCard-item col-12 col-lg-4">
            <ReferencesStoryCard
              title={item.title}
              image={item.image && <ProjectImage image={item.image} />}
              to={makeLink(item)}
            >
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </ReferencesStoryCard>
          </div>
        ))}
        <i className="col-4 d-none d-lg-block"></i>
      </Slider>
      {/* Arrow */}
      <div
        className="col-12 col-lg-3 d-lg-flex align-items-center ml-auto mt-32"
        style={{ minWidth: 120 + 'px', maxWidth: 145 + 'px' }}
      >
        <Slider
          className="col-12"
          ref={sliderArrowRef}
          options={{
            prevNextButtons: true
          }}
        >
          {stories.map((obj, index) => (
            <i key={index}></i>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Story
