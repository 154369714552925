import React from 'react'

import ReferencesCard from '@c/references-card'
import ProjectImage from '@/components/project-image'

const Hero = ({ miniSuccessDetails }) => {
  return (
    <div className="container mt-60 mt-lg-80">
      <div className="row align-items-stretch">
        {miniSuccessDetails.map((item, index) => (
          <div
            className="col-12 col-lg-4 d-lg-flex align-items-lg-stretch mb-32"
            key={index}
          >
            <ReferencesCard
              value={item.titleBig}
              text={item.titleSmall}
              link={item.link}
              image={item.logo && <ProjectImage image={item.logo} />}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Hero
